// extracted by mini-css-extract-plugin
export var btnNoOutline = "global-module--btnNoOutline--f3133";
export var btnSkyBlue = "global-module--btnSkyBlue--3ec0e";
export var dotLoading = "global-module--dotLoading--f709a";
export var dotLoadingWhite = "global-module--dotLoadingWhite--425b7";
export var dots = "global-module--dots--979d0";
export var dotsWhite = "global-module--dots-white--ec033";
export var highlighter = "global-module--highlighter--72be9";
export var infoButton = "global-module--infoButton--308ea";
export var outlineInfo = "global-module--outlineInfo--4f8fb";
export var outlineSuccess = "global-module--outlineSuccess--6056e";
export var spin = "global-module--spin--754fd";
export var spin2s = "global-module--spin2s--13599";
export var spinningIcon = "global-module--spinningIcon--af74f";
export var textRight = "global-module--textRight--75273";