import { navigate } from "gatsby";
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import log from "../log";
import axios from "../../api/axios"
import * as css from "./Testimonial.module.css"
import formatNumber from "../checkout/formatNumber";

const Testimonial = (props: any) => {
    const tweets = [
        {
            name: 'Cat Lovers',
            handle: '@tobby_walker',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/tobby_walker.webp',
            tweet: 'Karna pinjol butuh ktp juga ygy 😼',
            image: [
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/tobby_walker.mp4',
            ],
            link: 'https://www.tiktok.com/@tobby_walker/video/7358317770724904197',
            isTiktok: true,
        },
        {
            name: 'ann',
            handle: '@catchingfeelinn',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/catchingfeelinn.webp',
            tweet: 'Info pinjol 🐈 untuk beli whiskas, syarat ktp',
            image: ['https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/catchingfeelinn.webp'],
            link: 'https://twitter.com/kochengfs/status/1604378034496118785',
        },
        {
            name: 'KTP Anabul',
            handle: '@KtpAnabul',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/ktpanabul.webp',
            tweet: 'Testimoni KTP Fisik! Terima kasih kak 😻',
            image: [
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/ktpanabul_1.webp',
            ],
            link: '#',
        },
        {
            name: 'pipiyeol',
            handle: '@pipiyeol',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/pipiyeol.webp',
            tweet: 'Telimaacih, om. Pipi punyak KTP sekarang dah legal 🤓🤓🤓,',
            image: [
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/pipiyeol_01.webp',
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/pipiyeol_02.webp',
            ],
            link: 'https://twitter.com/pipiyeol/status/1610827497163358208',
        },
        {
            name: 'ra',
            handle: '@tibatibakabjagi',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/tibatibakabjagi.webp',
            tweet: 'Yeyyyy,',
            image: [
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/tibatibakabjagi.webp',
            ],
            link: 'https://twitter.com/tibatibakabjagi/status/1602608339908972545',
        },
        {
            name: 'Gloomybaby',
            handle: '@gloomyyybaby',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/gloomyyybaby.webp',
            tweet: 'Anak bulu ku udah legal ges 🤙  Maap nik nya di sensor 🙏 takut di pake pawnjol buat beli RC 😭 <span>@KtpAnabul</span>',
            image: [
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/gloomyyybaby.webp',
            ],
            link: 'https://twitter.com/Gloomyyybaby/status/1613136769750532097',
        },
        {
            name: 'buyur',
            handle: '@J_lian8',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/j_lian8.webp',
            tweet: 'BUC soalnya babu pelit mo beli snack',
            image: ['https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/j_lian8.webp'],
            link: 'https://twitter.com/J_lian8/status/1604817119816089602',
        },
        {
            name: 'Khakim Hudaya',
            handle: '@huedaya',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/huedaya.webp',
            tweet: 'Tema baru <span>@KtpAnabul</span>! 🎨🐈<br/><br/>Cocok untuk anabul kamu yang suka ngeong-ngeong tiap malam. 😂<br/><br/> → <span>https://ktpanabul.com</span>',
            image: ['https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/huedaya.webp'],
            link: 'https://twitter.com/huedaya/status/1608426540844617730',
        },
        {
            name: 'watashiwa 🍥',
            handle: '@Kiimpossiblew',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/kiimpossiblew.webp',
            tweet: 'Mengingat sempat hilang, mari kita buat KTP nya <span>#mikey</span> di <span>@KtpAnabul</span> wkwk',
            image: [
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/kiimpossiblew_01.webp',
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/kiimpossiblew_02.webp',
            ],
            link: 'https://twitter.com/Kiimpossiblew/status/1610917645372780544',
        },
        {
            name: 'tata',
            handle: '@swtycupcakes',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/swtycupcakes.webp',
            tweet: 'done. sementara pakenya e-ktp dulu kack🤣',
            image: [
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/swtycupcakes.webp',
            ],
            link: 'https://twitter.com/swtycupcakes/status/1602374269010223104',
        },
        {
            name: 'Maria🌬️',
            handle: '@yumetorouu',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/yumetorouu.webp',
            tweet: 'Uming udah punya KTP 🥰 <span>@KtpAnabul</span>',
            image: [
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/yumetorouu_1.webp',
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/yumetorouu_2.webp',
            ],
            link: 'https://twitter.com/yumetorouu/status/1603673851296387072',
        },
        {
            name: 'bondan_bmk',
            handle: '@bondan_bmk',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/bondan_bmk.webp',
            tweet: 'Chiro punya KTP <span>ktpanabul.com/@chiro</span>. <br/>Keren webnya <span>@heudaya</span> <span>@KtpAnabul</span>',
            image: [
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/bondan_bmk.webp',
            ],
            link: 'https://twitter.com/bondan_bmk/status/1602288562837151744',
        },
        {
            name: '{ amoi }',
            handle: '@yaemiewko',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/yaemiewko.webp',
            tweet: '| the mischievous lion got identified legally. <span>#rurupapoymemoy</span> <span>@KtpAnabul</span> <span>@mschvayato</span>',
            image: [
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/yaemiewko.webp',
            ],
            link: 'https://twitter.com/yaemiewko/status/1607428247628959744',
        },
        {
            name: 'nob',
            handle: '@JAEYONGlST',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/jaeyonglst.webp',
            tweet: 'iseng subscribe  <span>@KtpAnabul</span> sebulan. lucu juga.',
            image: [
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/jaeyonglst_1.webp',
            ],
            link: 'https://twitter.com/JAEYONGlST/status/1631530427273076736',
        },
        {
            name: 'Mika•IeL 🐾',
            handle: '@Gaddielprincess',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/gaddielprincess.webp',
            tweet: 'Terus ada adik aku paling kecil cimit dan sukanya makan sama nempel nempel miawmyy aku',
            image: [
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/gaddielprincess_1.webp',
            ],
            link: 'https://twitter.com/Gaddielprincess/status/1628758667549097984',
        },
        {
            name: 'Iis Susanti',
            handle: '@iissusan__',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/iissusan__.webp',
            tweet: '#ktpanabul',
            image: [
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/iissusan__.mp4',
            ],
            link: 'https://www.tiktok.com/@iissusan__/video/7302052834705542406',
            isTiktok: true,
        },
        {
            name: 'Anisa-chan✨',
            handle: '@per_aper',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/per_aper.webp',
            tweet: 'Yay! Makasih ya kak. KTP Anabul ku udah jadi😸',
            image: [
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/per_aper.webp',
            ],
            link: 'https://twitter.com/per_aper/status/1603671439915511808',
        },
        {
            name: 'zenaraaa🕊️',
            handle: '@kalaasenja_',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/kalaasenja_.webp',
            tweet: 'udah bikin, gemeeec🥲🫶',
            image: [
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/kalaasenja_01.webp',
            ],
            link: 'https://twitter.com/kalaasenja_/status/1602337425111814145',
        },
        {
            name: 'Khakim Hudaya',
            handle: '@huedaya',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/huedaya.webp',
            tweet: 'Tema baru <span>@KtpAnabul</span>! 🎨🐈<br/><br/>🌷🍀🌾Harvest 🐶🐱🎉<br/><br/>Terinspirasi dari video game legendaris Harvest Moon: Back to Nature. Tersedia untuk pengguna Pro.<br/><br/> → <span>https://ktpanabul.com</span>',
            image: ['https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/huedaya_2.webp'],
            link: 'https://twitter.com/huedaya/status/1626155500420358146',
        },
        {
            name: 'ini aku',
            handle: '@___npj___',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/npj.webp',
            tweet: 'asiik akhirnya yupi, fullo, dan regal udah punya ktp 🥳 thanks to <span>@KtpAnabul</span> <span>@huedaya</span>',
            image: [
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/npj_1.webp',
            ],
            link: 'https://twitter.com/___npj___/status/1614888668849926144',
        },
        {
            name: 'aaapi',
            handle: '@enyapusing',
            avatar: 'https://bucket-prod.ktpanabul.com/assets/testimonial/avatar/enyapusing.webp',
            tweet: 'siapa tau bisa dapet bansos royal canin ya wgwgwg',
            image: [
                'https://bucket-prod.ktpanabul.com/assets/testimonial/tweet/enyapusing.webp',
            ],
            link: 'https://twitter.com/enyapusing/status/1602493815226773504',
        },
    ];

    // Load statistic
    const [totalMember, setTotalMember] = React.useState(9012)
    const [totalIdCard, setTotalIdCard] = React.useState(9012)
    function loadLandingStats() {


        const get = async () => {
            try {
                let endpoint = '/api/public/landing_stats'
                log('debug', 'Calling API', {
                    endpoint: endpoint
                })

                const res = await axios.get(endpoint)
                if (res.status == 200) {
                    setTotalMember(res.data.data.total_member)
                    setTotalIdCard(res.data.data.total_id_card)
                    props.setTotalMember(res.data.data.total_member)
                    props.setTotalIdCard(res.data.data.total_id_card)
                    props.setMostRegisteredSpecies(res.data.data.species_distribution[0].label)
                    props.setMostRegisteredSpeciesNumber(res.data.data.species_distribution[0].count)
                    props.setMostRegisteredCity(res.data.data.pob_distribution[0].regency)
                    props.setMostRegisteredCityNumber(res.data.data.pob_distribution[0].count)
                }
            } catch (err: any) {
                if (err?.response == undefined) {
                    log("debug", "Failed reaching landing stats API.", {
                        exception: err,
                    })
                } else {
                    log("debug", err?.response?.data?.message, {
                        status: err?.response?.status,
                    })
                }
            }
        }
        get()
    }
    React.useEffect(() => {
        loadLandingStats();
    }, []);

    return (
        <>
            <div className={css.section}>
                <Container className={css.container}>
                    <div className={css.heading}>
                        <div className={css.number1}>
                            {formatNumber(totalIdCard, '')}
                        </div> KTP Anabul telah dibuat<br /> oleh&nbsp;
                        <div className={css.number2}>
                            {formatNumber(totalMember, '')}
                        </div> pengguna!
                    </div>
                    <div className={css.headingSubtitle}>
                        Berikut beberapa yang sudah jadi 👇
                    </div>
                    <div className={css.testimonials}>
                        {
                            tweets.map((item, index) => {
                                return (
                                    <a className={css.tweetBox} key={index} href={item.link} target="_blank">
                                        <Row>
                                            <Col className={css.accountInfo}>
                                                <div className={css.avatar}>
                                                    <img src={item.avatar} alt=".." />
                                                </div>
                                                <div className={css.name}>
                                                    {item.name}
                                                </div>
                                                <div className={css.handle}>
                                                    {item.handle}
                                                </div>
                                            </Col>
                                            <Col className={css.twitterLogo}>
                                                {
                                                    item?.isTiktok ? (
                                                        <img src="https://bucket-prod.ktpanabul.com/assets/tiktok-logo.svg" alt=".." />
                                                    ) : (
                                                        <img src="https://bucket-prod.ktpanabul.com/assets/twitter-logo.svg" alt=".." />
                                                    )
                                                }
                                            </Col>
                                        </Row>

                                        <Row className={css.tweetContent}>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: item.tweet }}
                                            />
                                        </Row>
                                        <Row>
                                            <div className={css.tweetMedia}>
                                                {
                                                    item.image.length == 1 ? (
                                                        <>
                                                            {item.image.map((imageItem, imageIndex) => {
                                                                // Check if the file extension is mp4
                                                                const isMp4 = imageItem.endsWith('.mp4');

                                                                console.log('imageItem', imageItem)
                                                                console.log('isMp4', isMp4)

                                                                return isMp4 ? (
                                                                    <div style={{
                                                                        position: "relative",
                                                                        width: "100%",
                                                                        height: "calc(35vw)",
                                                                        overflow: "hidden",
                                                                    }}>
                                                                        <video
                                                                            key={imageIndex}
                                                                            controls={false}
                                                                            autoPlay={true}
                                                                            muted={true}
                                                                            loop={true}
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: 0,
                                                                                left: 0,
                                                                                width: "100%",
                                                                                height: "100%",
                                                                                objectFit: "cover",
                                                                            }}
                                                                        >
                                                                            <source src={imageItem} type="video/mp4" />
                                                                            Your browser does not support the video tag.
                                                                        </video>
                                                                    </div>
                                                                ) : (
                                                                    <img src={item.image[0]} alt=".." />
                                                                );
                                                            })}
                                                        </>
                                                    ) : null
                                                }
                                                {
                                                    item.image.length == 2 ? (
                                                        <div className={css.multiImages} data-count={2}>
                                                            {
                                                                item.image.map((imageItem, imageIndex) => {
                                                                    return <div key={imageIndex} style={{ background: 'url(' + imageItem + ')' }}></div>
                                                                })
                                                            }
                                                        </div>
                                                    ) : null
                                                }

                                            </div>
                                        </Row>
                                    </a>
                                )
                            })
                        }

                    </div>
                </Container>
            </div>
        </>
    )
}

export default Testimonial
