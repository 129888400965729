import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { Suspense } from "react"
import { Col, Container, Row } from "react-bootstrap"

import * as css from "./Feature3.module.css"

import { Link } from "@gatsbyjs/reach-router"

import { Canvas, useFrame } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useLoader } from '@react-three/fiber'


function Model() {
    const [rotationDir, setRotationDir] = React.useState(false);
    const ref = React.useRef()
    const model = useLoader(GLTFLoader, process.env.APP_DO_SPACE_HOST + '/assets/3d/keychain_v2.glb?timestamp=12')

    useFrame((state, delta) => {
        let speed = 0.01
        ref.current.rotation.y += speed * (rotationDir ? -1 : 1)
        ref.current.rotation.z = -0.1
        ref.current.rotation.x = -0.2
    })

    return (
        <primitive
            object={model.scene}
            scale={2}
            ref={ref}
            position={[0, -2, 0.9]}
        />
    )
}

// markup
const Feature3 = () => {
    return (
        <>
            <div className={css.section}>
                <Container className={css.container}>
                    <Row>
                        <Col md={6} sm={12} className={css.colLeftSide}>
                            <div className={css.featureName}>😎 Halaman Profil + 🤳 Kalung QR</div>
                            <div className={css.featureHeading}>Nomor Telepon saja tidak cukup, tambahkan Kode QR!</div>
                            <div className={css.featureSummary}>
                                Setiap anabul berhak mendapat halaman <b>
                                    <a href="https://ktpanabul.com/@bonbon" target="_blank" title="Klik untuk melihat contoh Halaman Profil Anabul">
                                        Profil Anabul</a> secara gratis
                                </b>,
                                yang memuat informasi apakah anabul sedang hilang atau tidak.
                                <br />
                                <br />
                                Kami juga menyediakan pembuatan <b>Kalung QR</b> berbahan akrilik dengan tambahan instruksi dalam dua bahasa.
                            </div>

                            <Link to="/features/">
                                <div className={css.goToStart}>
                                    Coba Sekarang <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                                </div>
                            </Link>
                        </Col>
                        <Col md={6} sm={12}>
                            <div className={css.wrapper}>
                                <Canvas>
                                    <ambientLight />
                                    <pointLight position={[10, 10, 10]} />
                                    <Model />
                                </Canvas>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className={css.accent0}></div>
                <div className={css.accent1}>
                    <div className={css.accent2} />
                </div>
            </div>
        </>
    )
}

export default Feature3
