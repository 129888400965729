// extracted by mini-css-extract-plugin
export var accountInfo = "Testimonial-module--accountInfo--7d541";
export var avatar = "Testimonial-module--avatar--b8a76";
export var container = "Testimonial-module--container--5b77d";
export var handle = "Testimonial-module--handle--b024b";
export var heading = "Testimonial-module--heading--04081";
export var headingSubtitle = "Testimonial-module--headingSubtitle--cd194";
export var multiImages = "Testimonial-module--multiImages--7c60b";
export var name = "Testimonial-module--name--e11dd";
export var number1 = "Testimonial-module--number1--23ff3";
export var number2 = "Testimonial-module--number2--6d5cf";
export var section = "Testimonial-module--section--00bbf";
export var testimonials = "Testimonial-module--testimonials--09071";
export var tweetBox = "Testimonial-module--tweetBox--498e7";
export var tweetContent = "Testimonial-module--tweetContent--7fc38";
export var tweetMedia = "Testimonial-module--tweetMedia--877cd";
export var twitterLogo = "Testimonial-module--twitterLogo--b13cc";