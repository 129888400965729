import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"

import * as css from "./Feature1.module.css"

import Typewriter from "typewriter-effect"
import useInterval from "use-interval"

import cat1 from "../../assets/images/cat1-sample.png"
import paw1 from "../../assets/images/paw1-sample.png"
import { Link } from "@gatsbyjs/reach-router"
import * as Scroll from 'react-scroll';
var scroll = Scroll.animateScroll;


// markup
const Feature1 = () => {

  const anabulName = ["BONBON", "CHIKO"]

  const Dashboard = (props: any) => {
    const [currentName, setCurrentName] = React.useState("")
    const [year, setYear] = React.useState(2012)

    useInterval(() => {
      if (year == 2012) {
        setYear(2013)
      } else {
        setYear(2012)
      }
    }, 5000 / 2)
    return (
      <div className={css.dashboard}>
        <div className={css.window}>
          <Row>
            <Col className={css.fieldName}>Nama Anabul</Col>
            <Col>
              <div className={css.inputName}>
                <Typewriter
                  onInit={typewriter => {
                    typewriter
                      .typeString(anabulName[0])
                      .callFunction(() => {
                        setCurrentName(anabulName[0])
                      })
                      .pauseFor(2500)
                      .deleteAll()
                      .callFunction(() => {
                        setCurrentName("-")
                      })
                      .typeString(anabulName[1])
                      .callFunction(() => {
                        setCurrentName(anabulName[1])
                      })
                      .pauseFor(2500)
                      .deleteAll()
                      .callFunction(() => {
                        setCurrentName("-")
                      })
                      .start()
                  }}
                  options={{
                    loop: true,
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className={css.fieldName}>Hobi</Col>
            <Col>
              <div className={css.multiSelect}>
                <ul>
                  <li>Makan</li>
                  <li>Tidur</li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className={css.fieldName}>Sahabat Anabul</Col>
            <Col>
              <div className={css.inputName}>Kim Hudaya</div>
            </Col>
          </Row>
          <Row>
            <Col className={css.fieldName}>Ciri Khusus</Col>
            <Col>
              <div className={css.inputName}>Bulu Kaki Berwarna Cokelat</div>
            </Col>
          </Row>
        </div>

        <div className={css.ktp}>
          <div className={css.topText}>
            PROVINSI JAWA TENGAH
            <br />
            KABUPATEN TEMANGGUNG
          </div>
          <div className={css.nik}>NIK&nbsp;&nbsp;&nbsp;: 1798740047989739737</div>
          <div className={css.leftSide}>
            Nama
            <br />
            Tempat/Tgl Lahir
            <br />
            Jenis Kelamin
            <br />
            Ketebalan Bulu
            <br />
            Jenis Bulu
            <br />
            Warna Mata
            <br />
            Ciri Khusus
            <br />
            Status Perkawinan
            <br />
            &nbsp;&nbsp;Makanan Favorit
            <br />
            &nbsp;&nbsp;Pekerjaan
            <br />
            &nbsp;&nbsp;Hobi
            <br />
            Sahabat Manusia
            <br />
            Berlaku Hingga
            <br />
          </div>
          <div className={css.rightSide}>
            : {currentName}
            <br />: TEMANGGUNG, 23-01-{year}
            <br />
            : Jantan
            <br />
            : Sedang
            <br />
            : Hitam
            <br />
            : Hijau/Hijau
            <br />
            : Bulu Kaki Berwarna Cokelat
            <br />
            : belum kawin
            <br />
            : nasi
            <br />
            : menumpahkan gelas
            <br />
            : makan, tidur
            <br />
            : kim hudaya
            <br />
            : seumur hidup
            <br />
          </div>

          <div className={css.passPhoto}>
            <img src={cat1} alt="Pass Photo" />
          </div>

          <div className={css.textUnderPassPhoto}>
            TEMANGGUNG
            <br />
            23-01-2022
          </div>

          <div className={css.paw} style={{ backgroundImage: "url(" + paw1 + ")" }}></div>
        </div>
      </div>
    )
  }

  const canvasRef = useRef(null)
  const canvas = canvasRef.current;
  if (canvas != null) {
    // @ts-expect-error
    const context = canvas.getContext('2d');
    let time = 0;
    const color = function (x: number, y: number, r: number, g: number, b: number) {
      context.fillStyle = `rgb(${r}, ${g}, ${b})`
      context.fillRect(x, y, 10, 10);
    }
    const R = function (x: number, y: number, time: number) {
      return (Math.floor(192 + 64 * Math.cos((x * x - y * y) / 300 + time)));
    }

    const G = function (x: number, y: number, time: number) {
      return (Math.floor(192 + 64 * Math.sin((x * x * Math.cos(time / 4) + y * y * Math.sin(time / 3)) / 300)));
    }

    const B = function (x: number, y: number, time: number) {
      return (Math.floor(192 + 64 * Math.sin(5 * Math.sin(time / 9) + ((x - 100) * (x - 100) + (y - 100) * (y - 100)) / 1100)));
    }

    const startAnimation = function () {
      for (var x = 0; x <= 30; x++) {
        for (var y = 0; y <= 30; y++) {
          color(x, y, R(x, y, time), G(x, y, time), B(x, y, time));
        }
      }
      time = time + 0.03;
      window.requestAnimationFrame(startAnimation);
    }

    startAnimation();

  }


  return (
    <>
      <div className={css.features} onClick={() => {
        scroll.scrollTo(800, {
          duration: 100,
          delay: 0,
          smooth: true,
        });
      }}>
        <span>Lihat Fitur </span>
        <svg className={css.arrows}>
          <path className={css.a1} d="M0 0 L30 32 L60 0"></path>
          <path className={css.a2} d="M0 20 L30 52 L60 20"></path>
          <path className={css.a3} d="M0 40 L30 72 L60 40"></path>
        </svg>
      </div>

      <div className={css.section}>
        <Container className={css.container}>
          <Row>
            <Col md={6} sm={12} className={css.colLeftSide}>
              <div className={css.featureName}>💳 KTP Digital</div>
              <div className={css.featureHeading}>Input data informasi, langsung jadi!</div>
              <div className={css.featureSummary}>
                Tidak perlu repot-repot belajar desain grafis untuk buat KTP Anabul yang unik ini, cukup masukan
                informasi yang dibutuhkan, dan KTP pun langsung jadi.
                <br />
                <br />
                Dilengkapi dengan <b>Teknologi A.I.</b> untuk membuat warna latar belakang pas-foto otomatis
                menyesuaikan tahun lahir.
              </div>
              <Link to="/features/">
                <div className={css.goToStart}>
                  Coba Sekarang <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                </div>
              </Link>

              <div className={css.goToNextFeature} onClick={() => {
                scroll.scrollTo(1500, {
                  duration: 100,
                  delay: 0,
                  smooth: true,
                });
              }}>
                Fitur Lainnya <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </Col>
            <Col md={6} sm={12}>
              <Dashboard />
            </Col>
          </Row>
        </Container>
        <div className={css.accent1}>
          <div className={css.accent2} />
          <div className={css.accent3}></div>
        </div>
        {/* <div className={css.accent4}></div> */}

        <canvas ref={canvasRef} className={css.canvasBlurAni} width="32px" height="32px"></canvas>
        <div className={css.canvasFade}></div>
      </div>
    </>
  )
}

export default Feature1
