import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import * as css from "./Feature2.module.css"

import useInterval from "use-interval"

import { Link } from "@gatsbyjs/reach-router"
import * as Scroll from 'react-scroll';
var scroll = Scroll.animateScroll;


// markup
const Feature2 = () => {
  const Dashboard = (props: any) => {
    const [selectedThemes, setSelectedThemes] = React.useState(0)
    const themes = [
      {
        name: "KTP Indo",
        image:
          "https://bucket-prod.ktpanabul.com/assets/card-themes-preview/card_style_ktp_indo_2000.png",
        sample: "https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_indo.webp",
      },
      {
        name: "KTP Anak",
        image: "https://bucket-prod.ktpanabul.com/assets/card-themes-preview/card_style_ktp_anak.png",
        sample: "https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_anak.webp",
      },
      {
        name: "Bunny Bottom",
        image:
          "https://bucket-prod.ktpanabul.com/assets/card-themes-preview/card_style_bunny_bottom.png",
        sample: "https://bucket-prod.ktpanabul.com/assets/card-themes-sample/bunny_bottom.webp",
      },
    ]

    useInterval(() => {
      if (selectedThemes < themes.length - 1) {
        setSelectedThemes(prev => prev + 1)
      } else {
        setSelectedThemes(0)
      }
    }, 1000)

    return (
      <div className={css.dashboard}>
        <div className={css.ktp}>
          {themes.map((item, index) => {
            return (
              <div
                key={index}
                className={selectedThemes == index ? css.active : null}
                style={{
                  backgroundImage: `url(${item.sample})`,
                }}
              ></div>
            )
          })}
        </div>

        <div className={css.window}>
          <Row>
            <Col>
              <div className={css.multiSelect}>
                <ul>
                  {themes.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={selectedThemes == index ? css.active : null}
                        style={{
                          backgroundImage: `url(${item.image})`,
                        }}
                      ></li>
                    )
                  })}
                </ul>
              </div>
              <div className={css.moreThemes}>
                &nbsp; &nbsp;&nbsp;Tema Lainnya <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
  // const cardItems = [
  //   {
  //     id: 1,
  //     title: "Stacked Card Carousel",
  //     copy:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet dui scelerisque, tempus dui non, blandit nulla. Etiam sed interdum est."
  //   },
  //   {
  //     id: 2,
  //     title: "Second Item",
  //     copy: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
  //   },
  //   {
  //     id: 3,
  //     title: "A Third Card",
  //     copy:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet dui scelerisque, tempus dui non, blandit nulla."
  //   },
  //   {
  //     id: 4,
  //     title: "Fourth",
  //     copy: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
  //   }
  // ];


  const cardItems = [
    {
      id: 0,
      name: "KTP Indo",
      image:
        "https://bucket-prod.ktpanabul.com/assets/card-themes-preview/card_style_ktp_indo_2000.png",
      sample: "https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_indo.webp",
    },
    {
      id: 1,
      name: "KTP Anak",
      image: "https://bucket-prod.ktpanabul.com/assets/card-themes-preview/card_style_ktp_anak.png",
      sample: "https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_anak.webp",
    },
    {
      id: 2,
      name: "Bunny Bottom",
      image:
        "https://bucket-prod.ktpanabul.com/assets/card-themes-preview/card_style_bunny_bottom.png",
      sample: "https://bucket-prod.ktpanabul.com/assets/card-themes-sample/bunny_bottom.webp",
    },
    {
      id: 3,
      name: "Petify",
      image:
        "https://bucket-prod.ktpanabul.com/assets/card-themes-preview/card_style_petify.png",
      sample: "https://bucket-prod.ktpanabul.com/assets/card-themes-sample/petify.webp",
    },
    {
      id: 4,
      name: "University",
      image:
        "https://bucket-prod.ktpanabul.com/assets/card-themes-preview/card_style_homan_meow.png",
      sample: "https://bucket-prod.ktpanabul.com/assets/card-themes-sample/homan_meow.webp",
    },
    {
      id: 5,
      name: "Harvest",
      image:
        "https://bucket-prod.ktpanabul.com/assets/card-themes-preview/card_style_harvest.png",
      sample: "https://bucket-prod.ktpanabul.com/assets/card-themes-sample/harvest.webp",
    },
    {
      id: 6,
      name: "Sehat",
      image:
        "https://bucket-prod.ktpanabul.com/assets/card-themes-preview/card_style_sehat.png",
      sample: "https://bucket-prod.ktpanabul.com/assets/card-themes-sample/sehat.webp",
    },
  ]
  function determineClasses(indexes: any, cardIndex: any) {
    if (indexes.currentIndex === cardIndex) {
      return "active";
    } else if (indexes.nextIndex === cardIndex) {
      return "next";
    } else if (indexes.previousIndex === cardIndex) {
      return "prev";
    }
    return "inactive";
  }

  const CardCarousel = () => {
    const [indexes, setIndexes] = React.useState({
      beforeNextIndex: 3,
      nextIndex: 2,
      currentIndex: 1,
      previousIndex: 0,
    });

    const handleCardTransition = React.useCallback(() => {
      setIndexes(prevState => ({
        beforeNextIndex: (prevState.beforeNextIndex < cardItems.length - 1 ? prevState.beforeNextIndex + 1 : 0),
        nextIndex: (prevState.nextIndex < cardItems.length - 1 ? prevState.nextIndex + 1 : 0),
        currentIndex: (prevState.currentIndex < cardItems.length - 1 ? prevState.currentIndex + 1 : 0),
        previousIndex: (prevState.previousIndex < cardItems.length - 1 ? prevState.previousIndex + 1 : 0),
      }));
    }, [indexes.currentIndex]);

    React.useEffect(() => {
      const transitionInterval = setInterval(() => {
        handleCardTransition();
      }, 1000);

      return () => clearInterval(transitionInterval);
    }, [handleCardTransition, indexes]);

    return (
      <div >
        <ul className={css.cardCarousel}>
          {cardItems.map((card, index) => (
            <li
              key={card.id}
              className={`card ${determineClasses(indexes, index)}`}
              style={{
                backgroundImage: `url(${card.sample})`,
              }}
            >
            </li>
          ))}
          <li
            key={99}
            className='card before-next'
            style={{
              backgroundImage: `url(${cardItems[indexes.beforeNextIndex].sample})`,
            }}
          >
          </li>
        </ul>
      </div>
    );
  };

  return (
    <>
      <div className={css.section}>
        <Container className={css.container}>
          <Row>
            <Col md={6} sm={12}>
              {/* <Dashboard /> */}
              <CardCarousel />
            </Col>
            <Col md={6} sm={12} className={css.colLeftSide}>
              <div className={css.featureName}> 🖌️ Pilihan Tema</div>
              <div className={css.featureHeading}>Jadikan KTP Anabul-mu beda dan unik!</div>
              <div className={css.featureSummary}>
                Tersedia berbagai pilihan tema yang diperbaharui secara berkala. Tidak perlu menginputkan informasi
                tambahan, cukup <i>*klik-klik*</i> beres!
              </div>

              <Link to="/features/">
                <div className={css.goToStart}>
                  Coba Sekarang <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                </div>
              </Link>
              <div className={css.goToNextFeature} onClick={() => {
                scroll.scrollTo(1900, {
                  duration: 100,
                  delay: 0,
                  smooth: true,
                });
              }}>
                Fitur Lainnya <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </Col>
          </Row>
        </Container>
        <div className={css.accent0}></div>
        <div className={css.accent1}>
          <div className={css.accent2} />
        </div>
      </div>
    </>
  )
}

export default Feature2
