// extracted by mini-css-extract-plugin
export var a1 = "Feature1-module--a1--cbb73";
export var a2 = "Feature1-module--a2--0b6db";
export var a3 = "Feature1-module--a3--c0cc3";
export var accent1 = "Feature1-module--accent1--e44db";
export var accent2 = "Feature1-module--accent2--22c96";
export var accent3 = "Feature1-module--accent3--35289";
export var accent4 = "Feature1-module--accent4--e5245";
export var animateAccent2 = "Feature1-module--animate-accent2--a879d";
export var arrow = "Feature1-module--arrow--12cc1";
export var arrows = "Feature1-module--arrows--8750b";
export var bgKtp = "Feature1-module--bg-ktp--578dc";
export var canvasBlurAni = "Feature1-module--canvasBlurAni--528f5";
export var canvasFade = "Feature1-module--canvasFade--81ed3";
export var colLeftSide = "Feature1-module--colLeftSide--97b2e";
export var container = "Feature1-module--container--2294f";
export var dashboard = "Feature1-module--dashboard--41c2d";
export var featureHeading = "Feature1-module--featureHeading--97548";
export var featureName = "Feature1-module--featureName--400c5";
export var featureSummary = "Feature1-module--featureSummary--fa353";
export var features = "Feature1-module--features--50c5f";
export var fieldName = "Feature1-module--fieldName--dd51c";
export var goToNextFeature = "Feature1-module--goToNextFeature--a1576";
export var goToStart = "Feature1-module--goToStart--6b74b";
export var inputName = "Feature1-module--inputName--9dda0";
export var ktp = "Feature1-module--ktp--8e4c0";
export var leftSide = "Feature1-module--leftSide--606ab";
export var multiSelect = "Feature1-module--multiSelect--2a880";
export var nik = "Feature1-module--nik--c9ff2";
export var passPhoto = "Feature1-module--passPhoto--8ae5b";
export var paw = "Feature1-module--paw--b9a80";
export var rightSide = "Feature1-module--rightSide--3ccda";
export var section = "Feature1-module--section--1900d";
export var textUnderPassPhoto = "Feature1-module--textUnderPassPhoto--7b857";
export var topText = "Feature1-module--topText--2b700";
export var window = "Feature1-module--window--5f219";