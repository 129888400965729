// extracted by mini-css-extract-plugin
export var active = "Feature2-module--active--f19f5";
export var cardCarousel = "Feature2-module--cardCarousel--2e9f2";
export var colLeftSide = "Feature2-module--colLeftSide--f38c9";
export var container = "Feature2-module--container--a151a";
export var dashboard = "Feature2-module--dashboard--e0bfe";
export var featureHeading = "Feature2-module--featureHeading--d24ab";
export var featureName = "Feature2-module--featureName--e9967";
export var featureSummary = "Feature2-module--featureSummary--64a46";
export var fieldName = "Feature2-module--fieldName--3efc9";
export var goToNextFeature = "Feature2-module--goToNextFeature--71c93";
export var goToStart = "Feature2-module--goToStart--c1760";
export var ktp = "Feature2-module--ktp--24422";
export var moreThemes = "Feature2-module--moreThemes--daa7a";
export var multiSelect = "Feature2-module--multiSelect--74f49";
export var section = "Feature2-module--section--604db";
export var window = "Feature2-module--window--31392";