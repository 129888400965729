import { navigate } from "gatsby";
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import * as css from "./AsSeenIn.module.css"

const AsSeenIn = () => {
    const logos = [
        {
            headline: 'JawaPos - Khakim Hudaya, Sosok di Balik Pembuatan KTP untuk Anabul',
            logoPos: [
                0,
                0,
                350,
                70
            ],
            link: 'https://www.jawapos.com/hobi-kesenangan/08/01/2023/khakim-hudaya-sosok-di-balik-pembuatan-ktp-untuk-anabul/',
        },
        {
            headline: 'RCTI Plus - Khakim Hudaya, Sosok di Balik Pembuatan KTP untuk Anabul',
            logoPos: [
                -340,
                0,
                190,
                70
            ],
            link: 'https://www.rctiplus.com/news/detail/gaya-hidup/3282748/khakim-hudaya-sosok-di-balik-pembuatan-ktp-untuk-anabul?utm_source=3portalwidget&utm_medium=referral&utm_campaign=news_artikel_3282748',
        },
        {
            headline: 'Tiktok',
            logoPos: [
                -925,
                0,
                230,
                70
            ],
            link: 'https://vt.tiktok.com/ZSF4V6jp6/',
        },
        {
            headline: 'MomsMoney.id - Cara Membuat KTP Kucing Secara Online, Gratis untuk Anabul Kesayangan di Rumah',
            logoPos: [
                -535,
                0,
                180,
                70
            ],
            link: 'https://www.momsmoney.id/news/cara-membuat-ktp-kucing-secara-online-gratis-untuk-anabul-kesayangan-di-rumah',
        },
        // {
        //     headline: 'MUSEMEDIA - Super Gemas! Kini, Anabul Kesayangan Lo Bisa Punya KTP, Lho!',
        //     logoPos: [
        //         -712,
        //         0,
        //         220,s
        //         70
        //     ],
        //     link: 'https://musemedia.id/super-gemas-kini-anabul-kesayangan-lo-bisa-punya-ktp-lho/',
        // },
    ];

    return (
        <>
            <div className={css.section}>
                <Container className={css.container}>
                    <div className={css.logos}>
                        <div className={css.smallText}>seperti di ulas di: </div>
                        {
                            logos.map((item, index) => {
                                return (
                                    <a
                                        key={`aseen-${index}`}
                                        className={css.logo}
                                        style={{
                                            backgroundPosition: item.logoPos[0] + 'px ' + item.logoPos[1] + 'px',
                                            width: item.logoPos[2] + 'px ',
                                            height: item.logoPos[3] + 'px ',
                                        }}
                                        target="_blank"
                                        href={item.link}
                                    ></a>
                                )
                            })
                        }
                    </div>
                </Container>
            </div>
        </>
    )
}

export default AsSeenIn
