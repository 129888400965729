import * as React from "react"
import { Link, navigate } from "gatsby"
import "../assets/styles/global.css"
import { Navbar, Nav, Container, Dropdown, DropdownButton } from "react-bootstrap"

import * as styles from "./HeaderLandingPage.module.css"
import logoLightBasic from "../assets/images/ktp_anabul_logo_basic.png"
import logoLightPro from "../assets/images/ktp_anabul_logo_pro.png"
import logoLightDark from "../assets/images/ktp_anabul_logo_dark.png"
import useAuth from "../hooks/useAuth"
import useIsInPro from "../hooks/useIsInPro"
import ModalHelpBox from "./help/ModalHelpBox"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { css } from "react-select/dist/declarations/src/components/Control"

const HeaderLandingPage = () => {

    const [isDropdownExpanded, setIsDropdownExpanded] = React.useState(false);
    const { isAuthenticated } = useAuth()

    /**
     * Change home target if user already authenticated
     */
    let homeTarget = "/"
    if (isAuthenticated) {
        homeTarget = "/dashboard"
    }


    return (
        <>
            <Navbar expand="lg" className={styles.navbar}>
                <Container>
                    <Link to={homeTarget}>
                        <Navbar.Brand className={styles.navbarBrand}>
                            <img src={logoLightDark} alt="KTP Anabul" />
                        </Navbar.Brand>
                    </Link>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" className={styles.navbarToggler} />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end landing-header-link">
                        <Nav>
                            <div className={'nav-link'}>
                                <Link to="/features">Fitur 💎</Link>
                            </div>
                            <div className={'nav-link'}>
                                <Link to="/mission">Misi 💫</Link>
                            </div>

                            <div className={styles.hiddenMenuContainer} data-is-expanded={isDropdownExpanded}>
                                <div className={'nav-link'}>
                                    <Link to="/missing-pets">Anabul Hilang 🔎</Link>
                                </div>
                                <div className={'nav-link'}>
                                    <Link to="/distribution-map">Peta Distribusi 🧭</Link>
                                </div>
                            </div>
                            <div className={styles.expand} onClick={() => setIsDropdownExpanded(!isDropdownExpanded)}>
                                <a>
                                    Menu{' '}
                                    {
                                        isDropdownExpanded ?
                                            <FontAwesomeIcon icon={faChevronUp} />
                                            : <FontAwesomeIcon icon={faChevronDown} />
                                    }
                                </a>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar >


            <ModalHelpBox />
        </>
    )
}

export default HeaderLandingPage
