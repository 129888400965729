import React from "react"
import * as css from "./AnimatedBackground.module.css"

const AnimatedBackground = () => {
    return (
        <div className={css.introBackgroundContainerContainer}>
            <div className={css.introBackgroundContainer}>
                <div className={css.introBackground}>
                    <div className={css.faderLayerLeft}></div>
                    <div className={css.faderLayerRight}></div>
                    <div className={css.faderLayerBottom}></div>
                    <div className={css.allRowsContainer}>
                        <div className={css.imageRow}>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/zoey.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_indo.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/homan_meow.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/ratato.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_anak.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/bunny_bottom.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/harvest.webp" />
                            </div>
                        </div>
                        <div className={css.imageRow}>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/bunny_bottom.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/harvest.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_anak.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/ratato.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_indo.webp" />
                            </div>


                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/homan_meow.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>
                        </div>
                        <div className={css.imageRow}>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/zoey.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_indo.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/harvest.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/ratato.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_anak.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/bunny_bottom.webp" />
                            </div>
                        </div>
                        <div className={css.imageRow}>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/bunny_bottom.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_anak.webp" />
                            </div>


                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/homan_meow.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/harvest.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_indo.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/zoey.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>
                        </div>
                        <div className={css.imageRow}>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/zoey.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/sehat.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/harvest.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/homan_meow.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_anak.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/bunny_bottom.webp" />
                            </div>
                        </div>
                        <div className={css.imageRow}>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/bunny_bottom.webp" />
                            </div>


                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/homan_meow.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/ratato.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/harvest.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_indo.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/zoey.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>
                        </div>
                        <div className={css.imageRow}>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/zoey.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_indo.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/homan_meow.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/ratato.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_anak.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/harvest.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/bunny_bottom.webp" />
                            </div>
                        </div>
                        <div className={css.imageRow}>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/harvest.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/bunny_bottom.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/sehat.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/ratato.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_indo.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/zoey.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>
                        </div>
                        <div className={css.imageRow}>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/zoey.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_indo.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/ratato.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_anak.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/bunny_bottom.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/harvest.webp" />
                            </div>
                        </div>
                        <div className={css.imageRow}>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/bunny_bottom.webp" />
                            </div>


                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/homan_meow.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/ratato.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_indo.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/zoey.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/harvest.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>
                        </div>
                        <div className={css.imageRow}>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/homan_meow.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/zoey.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_indo.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/ratato.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_anak.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/harvest.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/bunny_bottom.webp" />
                            </div>
                        </div>
                        <div className={css.imageRow}>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/harvest.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/bunny_bottom.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_anak.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/ratato.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_indo.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/homan_meow.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>
                        </div>
                        <div className={css.imageRow}>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/zoey.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_indo.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/harvest.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/ratato.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_anak.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/bunny_bottom.webp" />
                            </div>
                        </div>
                        <div className={css.imageRow}>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/harvest.webp" />
                            </div>
                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/bunny_bottom.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_anak.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/ratato.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/card-themes-sample/ktp_indo.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/zoey.webp" />
                            </div>

                            <div className={css.imageItem}>
                                <img src="https://bucket-prod.ktpanabul.com/assets/swiper-sample/bonbon.webp" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnimatedBackground
