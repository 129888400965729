// extracted by mini-css-extract-plugin
export var colLeftSide = "Feature3-module--colLeftSide--a16ad";
export var container = "Feature3-module--container--bb19d";
export var dashboard = "Feature3-module--dashboard--8b71f";
export var featureHeading = "Feature3-module--featureHeading--006fe";
export var featureName = "Feature3-module--featureName--09e0e";
export var featureSummary = "Feature3-module--featureSummary--a87a5";
export var goToStart = "Feature3-module--goToStart--96ac4";
export var ktp = "Feature3-module--ktp--0029f";
export var section = "Feature3-module--section--01feb";
export var window = "Feature3-module--window--3fead";
export var wrapper = "Feature3-module--wrapper--e1fb8";